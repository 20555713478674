// Leaderboard.js
import React, { useState, useEffect } from 'react';
import { db } from './firebase'; // Ensure your Firestore is initialized
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import './Leaderboard.css'; // Create a CSS file for the leaderboard styling

const Leaderboard = () => {
    const [topPlayers, setTopPlayers] = useState([]);

    useEffect(() => {
        const fetchTopScores = async () => {
            const scoresRef = collection(db, 'scores');
            const q = query(scoresRef, orderBy('score', 'desc'), limit(10)); // Get top 10 scores
            const querySnapshot = await getDocs(q);
            const players = querySnapshot.docs.map(doc => ({
                walletAddress: doc.id,
                score: doc.data().score,
            }));
            console.log(players)
            setTopPlayers(players);
        };

        fetchTopScores();
    }, []);

    return (
        <div className="leaderboard-container">
            <img src="dogioo_logo.png" alt="Dogioo Logo" style={{ width: "100px", height: "100px" }} />
            <h1>Leaderboard</h1>
            <table className="leaderboard-table">
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Wallet Address</th>
                        <th>Score</th>
                    </tr>
                </thead>
                <tbody>
                    {topPlayers.map((player, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{player.walletAddress}</td>
                            <td>{player.score}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Leaderboard;