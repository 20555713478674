import React from 'react';
import './Whitepaper.css'; // You will create this CSS file

const Whitepaper = () => {
    return (
        <div className="whitepaper-container">
            <div className="whitepaper-content">
                <h1>DOGIOO PAPER</h1>
                <p>Version 1.0</p>

                <h2>INTRODUCTION</h2>
                <p>
                    Welcome to the Dogioo Whitepaper. This document outlines the vision, mission, and technology behind the Dogioo project. We are dedicated to building a fun, interactive, and decentralized ecosystem where users can play, engage, and earn in a game-like environment. Our native token, **$DIOO**, fuels the ecosystem, allowing players to access rewards, purchase NFTs, and engage in governance.
                </p>

                <h2>VISION</h2>
                <p>
                    Our vision is to create a digital ecosystem where users are rewarded for their engagement and contributions. Dogioo leverages blockchain technology and cryptocurrency to provide a seamless experience for players, turning interactions into real value. With **$DIOO**, players will be able to truly own their in-game assets and participate in an open marketplace.
                </p>

                <h2>TECHNOLOGY</h2>
                <p>
                    The Dogioo platform is built using cutting-edge technologies, including:
                    <ul>
                        <li>**Solana Blockchain** for decentralized rewards and true asset ownership.</li>
                        <li>**AI** for dynamic game behavior, enhancing player experiences.</li>
                        <li>**Secure wallet integration** for easy in-game transactions and NFT management.</li>
                    </ul>
                </p>

                <h2>Tokenomics</h2>
                <p>
                    The **$DIOO** token plays a central role in the Dogioo ecosystem. Here’s the breakdown of the tokenomics:
                </p>
                <div>
                    <p>**85% of Tokens allocated to the Liquidity Pool (LP), ensuring a stable and liquid market for trading and transactions.</p>
                    <p>**10% of Tokens reserved for Player Rewards, allowing users to earn **$DIOO** through gameplay, competitions, and in-game achievements.</p>
                    <p>**5% of Tokens allocated for Taxes, Marketing, and Listings on centralized and decentralized exchanges, ensuring the growth and visibility of Dogioo.</p>
                </div>

                <h2>GAMEPLAY</h2>
                <p>
                    The gameplay revolves around collecting points by interacting with in-game elements and participating in fun, AI-driven meme-based challenges. Players will have opportunities to purchase special in-game items using **$DIOO**, view leaderboards, earn exclusive NFTs, and access premium content in our game universe.
                </p>

                <h2>ROADMAP</h2>
                <p>
                    - **Q3 2024**: Token Launch<br />
                    - **Q4 2024**: Build<br />
                    - **Q1 2025**: TGE - **$DIOO** Rewards / Listings<br />
                    - **Q1 2024**: Launch of Game Marketplace<br />
                    - **Q2 2025**: Expansion of Marketplace for Game Collectibles<br />
                </p>

                <h2>THE FUTURE OF FUN</h2>
                <p>
                    Thank you for reading our whitepaper. We are excited to have you onboard as we continue to develop and expand the Dogioo platform. With the launch of **$DIOO** and our NFT marketplace, the future of gaming is here! Stay tuned for more updates, and let the meme games begin!
                </p>
            </div>
        </div>
    );
};

export default Whitepaper;