import nacl from 'tweetnacl';
import bs58 from 'bs58';

// Generate key pair using tweetnacl
export const generateKeyPair = () => {
    return nacl.box.keyPair();
};

// Generate the deep link for connecting with Phantom and handling redirects
export const generateConnectDeepLink = (publicKey) => {
    const appUrl = encodeURIComponent(window.location.origin); // Your app's URL
    const redirectLink = encodeURIComponent(`${window.location.origin}/onConnect`); // Redirect after connecting
    const dappEncryptionPublicKey = bs58.encode(publicKey); // Use the generated public key

    // Set cluster to devnet (you can change it to mainnet-beta or testnet as needed)
    const cluster = 'devnet';

    // Construct the full deep link URL
    return `https://phantom.app/ul/v1/connect?app_url=${appUrl}&dapp_encryption_public_key=${dappEncryptionPublicKey}&redirect_link=${redirectLink}&cluster=${cluster}`;
};