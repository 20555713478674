import React, { useState, useEffect } from 'react';
import { generateConnectDeepLink, generateKeyPair } from './utils/walletUtils';
import './App.css';

const WalletConnection = ({ setWalletAddress }) => {
    const [status, setStatus] = useState('Wallet not connected');
    const [isWalletConnected, setIsWalletConnected] = useState(false);
    const [isMobile, setIsMobile] = useState(false); // Detect mobile devices
    const [dappKeyPair] = useState(generateKeyPair()); // Generate the key pair for encryption
    const [menuOpen, setMenuOpen] = useState(false); // Menu state

    // Function to check if the device is mobile
    const checkIfMobile = () => {
        const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobile(isMobileDevice);
    };

    // Function to reconnect if wallet was previously connected (on desktop)
    const reconnectPhantomBrowser = async () => {
        const savedPublicKey = localStorage.getItem('phantomPublicKey');
        if (savedPublicKey) {
            // Phantom is installed and wallet was previously connected
            setWalletAddress(savedPublicKey);
            setIsWalletConnected(true);
            setStatus(`Wallet connected: ${savedPublicKey}`);
        } else if (window.solana && window.solana.isPhantom) {
            // Phantom is installed but no saved connection, check if wallet is connected
            try {
                const response = await window.solana.connect({ onlyIfTrusted: true });
                const publicKey = response.publicKey.toString();
                setWalletAddress(publicKey);
                setIsWalletConnected(true);
                setStatus(`Wallet connected: ${publicKey}`);
                localStorage.setItem('phantomPublicKey', publicKey); // Save the public key
            } catch (err) {
                console.error('Failed to reconnect with Phantom browser extension:', err);
                setStatus('Failed to reconnect. Please try again.');
            }
        }
    };

    // Function to handle desktop connection (using Phantom browser extension)
    const connectPhantomBrowser = async () => {
        try {
            const response = await window.solana.connect();
            const publicKey = response.publicKey.toString();
            setWalletAddress(publicKey);
            setIsWalletConnected(true);
            setStatus(`Wallet connected: ${publicKey}`);
            localStorage.setItem('phantomPublicKey', publicKey); // Save the public key in localStorage
        } catch (err) {
            console.error('Failed to connect with Phantom browser extension:', err);
            setStatus('Connection failed. Try again.');
        }
    };

    // Function to handle mobile connection redirect and extract query parameters
    const handleMobileRedirect = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const publicKey = searchParams.get('phantom_encryption_public_key'); // Extract the public key

        if (publicKey) {
            setWalletAddress(publicKey);
            setIsWalletConnected(true);
            setStatus(`Wallet connected: ${publicKey}`);
        } else {
            setStatus('Failed to retrieve wallet details from Phantom after redirect.');
        }
    };

    // Function to handle mobile connection (via deep link)
    const connectPhantomMobile = () => {
        try {
            const deepLinkUrl = generateConnectDeepLink(dappKeyPair.publicKey); // Generate the deep link with encryption public key
            window.location.href = deepLinkUrl; // Redirect the user to the Phantom deep link
        } catch (err) {
            console.error('Failed to initiate Phantom connection on mobile:', err);
            setStatus('Connection failed. Try again.');
        }
    };

    // Main function to initiate connection based on platform (desktop or mobile)
    const connectWallet = async () => {
        if (isMobile) {
            // Use deep link on mobile
            connectPhantomMobile();
        } else if (window.solana && window.solana.isPhantom) {
            // Use the Phantom browser extension on desktop
            connectPhantomBrowser();
        } else {
            setStatus('Phantom Wallet not found. Please install it.');
        }
    };

    // Function to disconnect wallet
    const disconnectWallet = () => {
        setWalletAddress(null);
        setIsWalletConnected(false);
        setStatus('Wallet disconnected.');
        localStorage.removeItem('phantomPublicKey'); // Remove from localStorage on desktop
    };

    // Check if mobile and handle desktop or mobile reconnect scenarios
    useEffect(() => {
        checkIfMobile();

        if (!isMobile) {
            // Desktop scenario: Try to reconnect Phantom wallet
            reconnectPhantomBrowser();
        } else {
            // Mobile scenario: Handle the redirect from Phantom
            handleMobileRedirect();
        }
    }, [isMobile]);

    return (
        <div className="game-container">
            <div style={{ display: 'block' }}>
                <div className="logo_container">
                    <img src="dogioo_logo.png" alt="Dogioo Logo" className="logo" />
                </div>
                <div>
                    {isWalletConnected ? (
                        <div>
                            <p>{status}</p>
                            <button onClick={disconnectWallet} className="disconnect-button">
                                Disconnect Wallet
                            </button>
                        </div>
                    ) : (
                        <div style={{ textAlign: 'center' }}>
                            <button onClick={connectWallet} className="connect-button">
                                Connect Wallet
                            </button>
                            <p className="status">{status}</p>
                        </div>
                    )}
                </div>
                <div>
                    <p style={{ marginTop: '40px', textAlign: 'center' }}>Connect your wallet to enter Dogioo</p>
                </div>
                {menuOpen && (
                    <div className="menu-overlay">
                        <div className="menu-content">
                            <a href="https://raydium.io/swap/?inputCurrency=SOL&outputCurrency=BoQh1EEnhwRyNPto81HWipux8MCsdbfZMt9UKCpuPB44&inputMint=So11111111111111111111111111111111111111112&outputMint=BoQh1EEnhwRyNPto81HWipux8MCsdbfZMt9UKCpuPB44" target="_blank" rel="noopener noreferrer">
                                <button className="menu-button">Buy</button>
                            </a>
                            <a href="/whitepaper" target="_blank" rel="noopener noreferrer">
                                <button className="menu-button">Whitepaper</button>
                            </a>
                            <a href="/leaderboard" target="_blank" rel="noopener noreferrer">
                                <button className="menu-button">Leaderboard</button>
                            </a>
                            <button onClick={() => setMenuOpen(false)} className="menu-button">Close Menu</button>
                        </div>
                        <div className="telegram">
                            <SocialLink href="https://t.me/realdogioochat" icon="bi-telegram" />
                            <SocialLink href="https://www.tiktok.com/@realdogioo?_t=8pYCD7GXLt7&_r=1" icon="bi-tiktok" />
                            <SocialLink href="https://x.com/realDogioo" icon="bi-twitter-x" />
                        </div>
                    </div>
                )}
            </div>
            <div className="burger-menu" onClick={() => setMenuOpen(true)}>
                <div className="burger-icon"></div>
                <div className="burger-icon"></div>
                <div className="burger-icon"></div>
            </div>
        </div>
    );
};

export default WalletConnection;


const SocialLink = ({ href, icon }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#ffcc00" className={`bi ${icon}`} viewBox="0 0 16 16">
            <path d={getIconPath(icon)} />
        </svg>
    </a>
);

const getIconPath = (icon) => {
    switch (icon) {
        case 'bi-telegram':
            return "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09";
        case 'bi-tiktok':
            return "M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z";
        case 'bi-twitter-x':
            return "M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z";
        default:
            return "";
    }
};
