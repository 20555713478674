import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore'; // Firestore methods
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from './firebase'; // Firestore initialization
import './Game.css';

const Game = ({ walletAddress, disconnectWallet }) => {
    const [score, setScore] = useState(0);
    const [dailyScore, setDailyScore] = useState(0); // New state to track daily score
    const [dogPosition, setDogPosition] = useState({ x: 0, y: 0 });
    const [woofVisible, setWoofVisible] = useState(false); // Track WOOF visibility
    const [error, setError] = useState(null); // For handling errors like daily limit
    const [showBadDog, setShowBadDog] = useState(false); // Toggle between good and bad dog
    const [showRedGlow, setShowRedGlow] = useState(false); // Red glow effect
    const [menuOpen, setMenuOpen] = useState(false); // Menu state
    const [isMobile, setIsMobile] = useState(false); // Track mobile status
    const [isOffline, setIsOffline] = useState(!navigator.onLine); // Track network connection

    const functions = getFunctions();

    // Fetch the score from Firestore when the game loads
    const fetchScore = async () => {
        try {
            const docRef = doc(db, 'scores', walletAddress);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setScore(data.score); // Set total score
                setDailyScore(data.dailyScore); // Set today's score
            } else {
                setScore(0); // Set score to 0 if no document exists
                setDailyScore(0); // Set daily score to 0 if no document exists
            }
        } catch (error) {
            console.error('Error fetching score:', error);
        }
    };

    const incrementScore = async () => {
        const incrementScoreFunc = httpsCallable(functions, 'incrementScore');
        try {
            const result = await incrementScoreFunc({ walletAddress });
            setScore(result.data.score);
            setDailyScore(result.data.dailyScore); // Update daily score
            setError(null); // Clear any previous errors
        } catch (error) {
            if (error.code === 'failed-precondition') {
                setError('You have reached the daily limit of 1000 points.');
            } else {
                console.error('Error updating score:', error);
            }
        }
    };

    const decrementScore = () => {
        setScore((prevScore) => Math.max(0, prevScore - 1)); // Decrement score by 1, but not below 0
    };

    const moveDog = () => {
        const x = Math.random() * (window.innerWidth - 80);
        const y = Math.random() * (window.innerHeight - 80);
        setDogPosition({ x, y });
    };

    const handleGoodDogClick = () => {
        if (!error) {
            incrementScore(); // Increment and update score when good dog is clicked
            moveDog();
            setWoofVisible(true); // Show WOOF text
            setTimeout(() => {
                setWoofVisible(false); // Hide WOOF after 1 second
            }, 1000);
        }
    };

    const handleBadDogClick = () => {
        decrementScore(); // Decrement score when bad dog is clicked
        setShowRedGlow(true); // Show the red glow when bad dog is clicked
        setShowBadDog(false); // Hide the bad dog after it's clicked
        setTimeout(() => {
            setShowRedGlow(false); // Hide the red glow after 1 second
        }, 1000);
    };

    // Randomly show bad dog
    const randomlyShowBadDog = () => {
        const randomTime = Math.random() * 5000 + 5000; // Randomly show the bad dog between 5 and 10 seconds
        setTimeout(() => {
            setShowBadDog(true); // Show bad dog
            moveDog(); // Move bad dog to a new position
            setTimeout(() => {
                setShowBadDog(false); // Hide bad dog after 3 seconds if not clicked
                moveDog(); // Move good dog to a new position
            }, 3000); // Bad dog stays for 3 seconds
        }, randomTime);
    };

    // Detect network connection and update state
    const updateNetworkStatus = () => {
        setIsOffline(!navigator.onLine);
    };

    useEffect(() => {
        // Detect if user is on mobile
        setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));

        // Move the dog to a random position when game starts
        moveDog();
        fetchScore(); // Fetch the score when the game loads
        randomlyShowBadDog(); // Randomly show bad dog during gameplay

        // Listen for network status changes
        window.addEventListener('online', updateNetworkStatus);
        window.addEventListener('offline', updateNetworkStatus);

        return () => {
            window.removeEventListener('online', updateNetworkStatus);
            window.removeEventListener('offline', updateNetworkStatus);
        };
    }, []);

    useEffect(() => {
        if (!showBadDog) {
            randomlyShowBadDog(); // Continue showing the good dog if bad dog is not shown
        }
    }, [showBadDog]);

    return (
        <div className="mobile-device-wrapper">
            <div className="mobile-screen">
                <div className="game-container">
                    {/* Red glow effect */}
                    {showRedGlow && <div className="red-glow"></div>}

                    {/* Show offline overlay if network connection is lost */}
                    {isOffline && (
                        <div className="offline-overlay">
                            <div className="offline-message">
                                <p>Network connection lost</p>
                            </div>
                        </div>
                    )}

                    {/* Display WOOF text when good dog is clicked */}
                    {woofVisible && (
                        <div
                            className="woof"
                            style={{ left: `${dogPosition.x}px`, top: `${dogPosition.y - 50}px` }} // Positioned near the dog
                        >
                            WOOF!
                        </div>
                    )}

                    <div className="scoreboard">
                        <p>Score: <span>{score}</span></p>
                        <p style={{ fontSize: '10px', marginTop: '5px' }}>Today's Score: <span style={{ fontSize: '10px' }}>{dailyScore}</span> / 1000</p>
                    </div>
                    {error && <p className="error-message">{error}</p>}

                    {/* Dog Image */}
                    <div
                        className="dog"
                        style={{ left: `${dogPosition.x}px`, top: `${dogPosition.y}px` }}
                        onClick={showBadDog ? handleBadDogClick : handleGoodDogClick} // Click handler changes based on the dog type
                    >
                        <img
                            src={showBadDog ? "dogs_logo.png" : "dogioo_logo.png"}
                            alt="dog"
                            style={{ width: '100px', height: '100px' }}
                        />
                    </div>

                    {/* Fullscreen Menu Overlay */}
                    {menuOpen && (
                        <div className="menu-overlay">
                            <div className="menu-content">
                                <a href="https://raydium.io/swap/?inputCurrency=SOL&outputCurrency=BoQh1EEnhwRyNPto81HWipux8MCsdbfZMt9UKCpuPB44&inputMint=So11111111111111111111111111111111111111112&outputMint=BoQh1EEnhwRyNPto81HWipux8MCsdbfZMt9UKCpuPB44" target="_blank" rel="noopener noreferrer">
                                    <button className="menu-button">
                                        Buy
                                    </button>
                                </a>

                                {/* Whitepaper link */}
                                <div className="menu-content">
                                    <a href="/whitepaper" target="_blank" rel="noopener noreferrer">

                                        <button className="menu-button">
                                            Whitepaper
                                        </button>
                                    </a>
                                </div>
                                <div className="menu-content">
                                    <button onClick={() => setMenuOpen(false)} className="menu-button">Close Menu</button>
                                </div>
                            </div>

                            <div className="telegram">
                                <a href="https://t.me/realdogioochat" target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffcc00" class="bi bi-telegram" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                                    </svg>
                                </a>
                                <a href="https://www.tiktok.com/@realdogioo?_t=8pYCD7GXLt7&_r=1" target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#ffcc00" class="bi bi-tiktok" viewBox="0 0 16 16">
                                        <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                                    </svg>
                                </a>
                                <a href="https://x.com/realDogioo" target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#ffcc00" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    )}

                    <div className="footer">
                        Dogioo - All rights reserved 2024
                    </div>

                    {/* Burger Menu */}
                    <div className="burger-menu" onClick={() => setMenuOpen(true)}>
                        <div className="burger-icon"></div>
                        <div className="burger-icon"></div>
                        <div className="burger-icon"></div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Game;