import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WalletConnection from './WalletConnection'; // Import WalletConnection
import Game from './Game';
import Whitepaper from './Whitepaper'; // Import the whitepaper page
import Leaderboard from './Leaderboard'; // Import the leaderboard page

function App() {
  const [walletAddress, setWalletAddress] = useState(null);

  return (
    <Router>
      <Routes>
        {/* If no wallet is connected, show WalletConnection */}
        <Route
          path="/"
          element={!walletAddress ? (
            <WalletConnection setWalletAddress={setWalletAddress} />
          ) : (
            <Game walletAddress={walletAddress} />
          )}
        />

        {/* Route for Whitepaper page */}
        <Route path="/whitepaper" element={<Whitepaper />} />
        <Route path="/leaderboard" element={<Leaderboard />} /> {/* Add leaderboard route */}

      </Routes>
    </Router>
  );
}

export default App;